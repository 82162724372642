import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'modalDialog', 'modalImagePreview', 'modalImageNumber']
  connect() {
    this.open = false
    const images = this.element.querySelectorAll('.library-image')
    this._sources = []
    images.forEach(i => {
      this._sources.push(i.src)
      i.addEventListener('click', this.handleImageClick.bind(this))
    })

    document.addEventListener('keyup', event => {
      if (!this.open) return
      if (event.key === 'ArrowLeft') {
        this.previousImage()
      } else if (event.key === 'ArrowRight') {
        this.nextImage()
      } else if (event.key === 'Escape') {
        this.closeModal()
      }
    })
  }

  closeModal() {
    this.modalTarget.classList.toggle('hidden', true)
    this.open = false
  }

  handleImageClick(event) {
    const image = this.modalImagePreviewTarget.querySelector('img')
    image.src = event.target.src
    this.modalTarget.classList.toggle('hidden', false)
    this.open = true
    this.modalImageNumberTarget.innerHTML = this._imageNumber(event.target.src)
  }

  _imageNumber(src) {
    return `${(this._sources.indexOf(src) + 1)} of ${this._sources.length}`
  }

  nextImage() {
    const image = this.modalImagePreviewTarget.querySelector('img')
    const index = this._sources.indexOf(image.src)
    const nexIndex = index === this._sources.length - 1 ? 0 : index + 1
    const newSource = this._sources[nexIndex]
    image.src = newSource
    this.modalImageNumberTarget.innerHTML = this._imageNumber(newSource)
  }

  previousImage() {
    const image = this.modalImagePreviewTarget.querySelector('img')
    const index = this._sources.indexOf(image.src)
    const previousIndex = index === 0 ? this._sources.length - 1 : index - 1
    const newSource = this._sources[previousIndex]
    image.src = newSource
    this.modalImageNumberTarget.innerHTML = this._imageNumber(newSource)
  }
}
