import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = ["count"]

  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "AlbumChannel",
        id: this.element.dataset['id'],
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    const json = JSON.parse(data)

    if (json.finished) {
      window.location.reload()
    } else {
      this.countTarget.innerHTML = json.image_count
    }
  }
}
