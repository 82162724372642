import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['nameField', 'totalField', 'suggestions']

  connect() {
    this.nameFieldTarget.addEventListener('change', this.handleNameChangeEvent.bind(this))
    this.nameFieldTarget.addEventListener('keyup', this.handleNameChangeEvent.bind(this))
    this.suggestions().forEach(suggestion => {
      suggestion.addEventListener('click', () => {
        this.nameFieldTarget.value = suggestion.dataset.name
        this.totalFieldTarget.value = suggestion.dataset.guest_total
        this.toggleSuggestions()
      })
    })
  }

  handleNameChangeEvent(event) {
    if (event.target.value === '') {
      this.toggleSuggestions()
    } else {
      const includes = this.suggestions().filter(suggestion => suggestion.innerText.toLowerCase().includes(event.target.value.toLowerCase()))
      const hidden = this.suggestions().filter(suggestion => !suggestion.innerText.toLowerCase().includes(event.target.value.toLowerCase()))
      this.toggleSuggestions(includes, false)
      this.toggleSuggestions(hidden)
    }
  }

  toggleSuggestions(suggestions = this.suggestions(), hidden = true) {
    suggestions.forEach(s => s.classList.toggle('hidden', hidden))
    if (this.suggestions().every(s => s.classList.contains('hidden'))) {
      this.suggestionsTarget.classList.toggle('hidden', true)
    } else {
      this.suggestionsTarget.classList.toggle('hidden', false)
    }
  }

  suggestions() {
    return [...this.suggestionsTarget.children]
  }

  closeTarget() {
    this.element.classList.add('hidden')
  }
}
