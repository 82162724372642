import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  LineController
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  LineController
);

export default class RSVPChartView extends React.Component {
  static propTypes = {
    labels: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
  }

  render() {
    const { labels, data } = this.props
    const options = {
      plugins: {
        title: {
          display: true,
          text: 'RSVPs per day',
        },
      },
      responsive: true,
      scales: {
        x: {
          // beginAtZero: true
        },
        y: {
          beginAtZero: true
        },
      }
    };

    const chartData = {
      labels,
      datasets: [
        {
          label: 'RSVPs',
          data,
          backgroundColor: '#713803',
          borderColor: '#713803',
          borderWidth: 2,
          pointHoverRadius: 5,
          pointHitRadius:8
        }
      ]
    }

    return (
      <Chart type="line" options={options} data={chartData}/>
    )
  }
}
