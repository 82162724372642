import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['menu', 'button']
  connect() {
    this.menuTarget.style.top = `${this.buttonTarget.clientHeight + 10}px`
    window.addEventListener('click', event => {
      if (event.target !== this.buttonTarget && !this.buttonTarget.contains(event.target)) {
        this.menuTarget.classList.toggle('hidden', true)
      }
    })
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden')
  }
}
