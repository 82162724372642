import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.dataset.confirmed = 'false'
    this.element.addEventListener('click', this.handleSubmitEvent.bind(this))
  }

  handleSubmitEvent(event) {
    if (event.target.dataset.confirmed === 'true') return
    event.preventDefault()

    const openEvent = new CustomEvent('open-confirm', {
      detail: {
        button: event.target,
        title: this.element.dataset.confirmtitle,
        body: this.element.dataset.confirmbody
      }
    })

    window.dispatchEvent(openEvent)
  }
}
