import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['title', 'body', 'close', 'submit']

  connect() {
    this.closeTarget.addEventListener('click', this.handleCloseEvent.bind(this))
    this.submitTarget.addEventListener('click', this.handleSubmitEvent.bind(this))
    window.addEventListener('open-confirm', this.openConfirm.bind(this))
  }

  openConfirm(event) {
    this.titleTarget.innerHTML = event.detail.title ?? ''
    this.bodyTarget.innerHTML = event.detail.body ?? ''
    this._button = event.detail.button
    this.element.classList.toggle('hidden', false)
  }

  handleCloseEvent(_event) {
    this.element.classList.toggle('hidden', true)
  }

  handleSubmitEvent(_event) {
    this._button.dataset.confirmed = 'true'
    this._button.click()
    this.titleTarget.innerHTML = ''
    this.bodyTarget.innerHTML = ''
    this._button = undefined
  }
}
